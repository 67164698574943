'use client';

import Box from '@mui/material/Box';
import LoginLayout from './layout';
import { LoginForms } from './forms';

export default function AdminLogin() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <LoginLayout>
          <LoginForms />
        </LoginLayout>
      </Box>
    </Box>
  );
}
