import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from '@/app/theme/css';
import Logo from '@/app/components/logo';

export default function LoginLayout({ children }) {
  const theme = useTheme();

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Stack
        flexGrow={1}
        spacing={10}
        alignItems="center"
        justifyContent="center"
        sx={{
          ...bgGradient({
            color: alpha(
              theme.palette.background.default,
              theme.palette.mode === 'light' ? 0.88 : 0.94
            ),
            imgUrl: '/assets/background/overlay_2.jpg',
          }),
        }}
      >
        <Stack
          spacing={0}
          sx={{
            width: 1,
            mx: 'auto',
            maxWidth: 480,
            px: { xs: 4, md: 8 },
            // pt: { xs: 15, md: 20 },
            py: { xs: 4, md: 8 },
            bgcolor: theme.palette.background.default,
            boxShadow: theme.customShadows.z16,
            borderRadius: theme.shape.borderRadius * 0.5,
          }}
        >
          <Stack flexDirection="row" justifyContent="center" alignItems="center">
            <Logo />
            <Typography variant="h4" sx={{ maxWidth: 480, textAlign: 'center' }}>
              Flix Pro Player Admin
            </Typography>
          </Stack>
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
}
