'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import qs from 'qs';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useSnackbar } from 'notistack';
import { useRouter } from 'next/navigation';
import { useBoolean } from '@/app/components/hooks/use-boolean';
import Iconify from '@/app/components/iconify';

import FormProvider from '@/app/components/hook-form/form-provider';
import RHFTextField from '@/app/components/hook-form/rhf-text-field';
import { axiosInstance } from '@/app/utilities/helpers/axios';
import { encryptRequest, decryptResponse } from '@/app/utilities/helpers/security';
import { useUserContext } from '@/app/components/contexts/user-context';

export const LoginForms = () => {
  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { setUser } = useUserContext();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async data => {
    try {
      const body = {
        data: encryptRequest({
          email: data.email,
          password: data.password,
        }),
      };

      const response = await axiosInstance.post(
        'sfn0LZOtDUctkbIc2o4a8ArqVz2ugHVy',
        qs.stringify(body),
        { withCredentials: true }
      );

      const responseData = decryptResponse(response.data);

      if (responseData.success) {
        setUser(responseData.admin);
        const redirectTo = localStorage.getItem('redirectAfterLogin') || '/';
        router.push(redirectTo);
        localStorage.removeItem('redirectAfterLogin');
        enqueueSnackbar('Log in successful', { variant: 'success' });
      } else {
        enqueueSnackbar(responseData.message, { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Server Error', { variant: 'error' });
    }
  });

  const loginHead = (
    <Stack spacing={2} sx={{ mb: 5, textAlign: 'center' }} alignItems="center">
      <Typography variant="h6">
        Login to your account and manage your orders, devices and settings
      </Typography>
    </Stack>
  );

  const loginForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="email" label="Email *" type="text" />

      <RHFTextField
        name="password"
        label="Password *"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {loginHead}

      {loginForm}
    </FormProvider>
  );
};
