import { Controller, useFormContext } from 'react-hook-form';

import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

export default function RHFTextField({ name, helperText, type, readOnly = false, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          type={type}
          value={type === 'number' && field.value === 0 ? '' : field.value}
          onChange={event => {
            if (type === 'mac-address') {
              const value = event.target.value.replace(/[^A-Fa-f0-9]/g, '');
              const formattedValue =
                value
                  .match(/.{1,2}/g)
                  ?.join(':')
                  .substring(0, 17) || '';
              field.onChange(formattedValue);
            } else {
              field.onChange(event.target.value);
            }
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          InputProps={{
            readOnly,
          }}
          {...other}
        />
      )}
    />
  );
}
